import React from "react"
import { Container, Row, Col } from "reactstrap"

import PageHeader from "../components/pageHeader"
import Service from "../components/service"

import Bulldozer from "../images/icons/icon-bulldozer-74x74.png"
import Tassels from "../images/icons/icon-tassels-70x70.png"
import Ruler from "../images/icons/icon-ruler-45x71.png"
import Suitcase from "../images/icons/icon-suitcase-73x57.png"
import Relues from "../images/icons/icon-relues-63x64.png"
import Helmet from "../images/icons/icon-helmet-67x65.png"



const card = [
  
  {
    title: `Programacion`,
    icon: Bulldozer,
    description: `Brindamos servicios de sistemas informaticos de alta calidad a las pymes , grandes empresas y usuarios finales.`,
  },
  {
    title: `Automatizacion`,
    icon: Tassels,
    description: `Transformamos tareas tediosas y repetitivas en tareas que se realizan con un simple click.`,
  },
  {
    title: `Diseño`,
    icon: Ruler,
    description: `Nuestro equipo de expertos tiene años de experiencia en el diseño de  soluciones de tanto programas como dispositivos electronicos.`,
  },
  /*
  {
    title: `Construction`,
    icon: Bulldozer,
    description: `We provide high-quality construction services to the energy, water and telecommunications markets.`,
  },
  {
    title: `Remodeling`,
    icon: Tassels,
    description: `Our team of experts has years of experience in remodeling homes including kitchens, basements etc.`,
  },
  {
    title: `Interior Design`,
    icon: Ruler,
    description: `Our interior design services are the best way to get a home design that completely fits you and your lifestyle.`,
  },
  */
  {
    title: `Contratacion`,
    icon: Suitcase,
    description: `Peluca Sistemas puede gestionar y contratar subcontratistas mientras actúa como contacto principal con los clientes del proyecto.`,
  },
  {
    title: `Pre-Desarrollo`,
    icon: Relues,
    description: `El Pre-Desarrollo implica realizar una planificación e ingeniería preliminares para definir el proyecto.`,
  },
  {
    title: `Gestión Ágil`,
    icon: Helmet,
    description: `El desarrollo de software ágil pesa mucho en la calidad de los proyectos y se utiliza en todos los proyectos de nuestra empresa.`,
  },
]
/*
function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <li>{props.value}</li>;
}

function NumberList(props) {
  const numbers = props.numbers;
  const listItems = numbers.map((service) =>
    // Correct! Key should be specified inside the array.
                  <Service services={service} />
  );
  return (
    <Col md="6" lg="4">
      {Service}
    </Col>
  );
}
*/

const ServicesPage = () => {
  const listItemslistasdas = card.map(service => {
    return (
      <Col md="6" lg="4" key={service.title}>
        <Service  services={service} />
      </Col>
    )
  })
return (
  <section id="services">
    <PageHeader title="Servicios" />
    <section className="services">
      <Container>
        <Row>
          {listItemslistasdas}
        </Row>
      </Container>
    </section>
  </section>
)
}

export default ServicesPage
